.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rc-anchor {
  border-radius: 3px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08)
}

.rc-inline-block {
  display: inline-block;
  height: 100%;
}

.rc-anchor-center-item {
  display: table-cell;
  vertical-align: middle;
}

.rc-anchor-center-container {
  display: table;
  height: 100%;
}

.rc-anchor-light {
  background: #f9f9f9;
  border: 1px solid #d3d3d3;
  color: #000;
  position: absolute !important;
  left: 0;
  bottom: 14px;
  margin-left: -182px;
}

.rc-anchor-light:hover {
  margin-left: 0;
}

.rc-anchor-normal {
  height: 60px;
  width: 256px;
  position: relative;
}

.rc-anchor-normal .rc-anchor-content {
  height: 60px;
  width: 150px;
  float: left;
  background: #4a90e2;
  color: white;
  flex-basis: 166px;
  flex-direction: column;
  flex-grow: 1;
  font-family: Roboto,helvetica,arial,sans-serif;
  font-size: 13px;
  font-weight: 400;
  justify-content: center;
  line-height: 20px;
  padding: 0 16px;
  white-space: nowrap;
  display: flex;
}

.rc-anchor-normal .rc-anchor-checkbox-label {
  width: 144px;
}

.rc-anchor-checkbox {
  margin: 0 12px 2px 12px;
}

.recaptcha-checkbox {
  border: none;
  font-size: 1px;
  height: 28px;
  margin: 4px;
  width: 28px;
  overflow: visible;
  outline: 0;
  vertical-align: text-bottom;
  display: block;
}

.recaptcha-checkbox-border {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #fff;
  border: 2px solid #c1c1c1;
  font-size: 1px;
  height: 24px;
  position: absolute;
  width: 24px;
}

.rc-anchor-checkbox-label {
  font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.rc-anchor-normal-footer {
  display: inline-block;
  height: 60px;
  vertical-align: top;
}

.rc-anchor-logo-portrait {
  margin: 5px 15px 0 15px;
  width: 44px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.rc-anchor-logo-img-portrait {
  background-size: 44px !important;
  height: 44px;
  width: 44px;
  margin: auto;
}

.rc-anchor-logo-img {
  background: url('https://www.gstatic.com/recaptcha/api2/logo_48.png') no-repeat;
}

.rc-anchor-logo-text {
  color: #9b9b9b;
  cursor: default;
  font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  margin-top: 5px;
  text-align: center;
}

.rc-anchor-normal .rc-anchor-pt,
.rc-anchor-compact .rc-anchor-pt {
  color: #9b9b9b;
  font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 8px;
  font-weight: 400;
}

.rc-anchor-normal .rc-anchor-pt {
  margin: 0;
}

.rc-anchor-pt a:link,
.rc-anchor-pt a:visited {
  color: #ffffff;
  font-size: 10px;
  text-decoration: none;
}

.recaptcha-checkbox-borderAnimation {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAANICAYAAABZl8i8AAAABmJLR…caGahTBej/IqDS5GVQOowHJTExMTExMTExMTExMTGx4Pb/Ab7rit24eUF+AAAAAElFTkSuQmCC) no-repeat;
  border: none;
  height: 30px;
  left: -5px;
  outline: 0;
  position: absolute;
  width: 28px;
}

.recaptcha-checkbox-spinner {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAscCAYAAAALLkmiAAAABmJLR…OqmOwj9LFhB/6A26e0msmsRAxskuaQZKyUU1yatK+i/X9jsJ4YiehNDAAAAABJRU5ErkJggg==) no-repeat;
  border: none;
  outline: 0;
  height: 36px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 36px;
}

.recaptcha-checkbox-spinnerAnimation {
  background-repeat: no-repeat;
  border: none;
  height: 38px;
  left: -5px;
  outline: 0;
  position: absolute;
  top: -5px;
  visibility: hidden;
  width: 38px;
}

.recaptcha-checkbox-checkmark {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAATsCAYAAADsAfBvAAAABmJLR…c8fQAAAIA4Ve/BleITOu7J3HehqXqHPH0AAAAAAAAAALr5H72AWmG4R73sAAAAAElFTkSuQmCC) no-repeat;
  border: none;
  height: 30px;
  left: -5px;
  outline: 0;
  position: absolute;
  width: 38px;
}

.color-black {
  font-family: Roboto,helvetica,arial,sans-serif;
  font-size: 8px !important;
  font-weight: 400 !important;
  color: black !important;
  text-align: center;
}
